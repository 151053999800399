import { Button, Image, useBreakpointValue } from '@chakra-ui/react'
import i18next from 'i18next'
import { useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { customerNameFromUrl } from '../../api/direct'
import { useConfig } from '../../context/config'
import { getLSItem } from '../../utils/localStorage'

type WidgetIconProps = {
  onClick: () => void
}

const widgetState = {
  DEFAULT: 'default',
  HOVERED: 'hovered',
}

export const WidgetIcon = ({ onClick }: WidgetIconProps) => {
  const { t } = useTranslation()
  const [state, setState] = useState(widgetState.DEFAULT)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const isActiveSession: boolean = !!getLSItem('user_id') && !!getLSItem('messages')
  const {
    apiConfig: { baseURL, customer },
  } = useConfig()
  const assetsBaseUrl = baseURL

  return (
    <Button
      w={'fit-content'}
      h={'fit-content'}
      position={'fixed'}
      zIndex='10'
      p={'0 !important'}
      bottom='5'
      right='2'
      bg={'unset !important'}
      outline={'unset !important'}
      aria-label={t('toggle')}
      onClick={onClick}
      onMouseEnter={() => {
        setState(widgetState.HOVERED)
      }}
      onMouseOut={() => {
        setState(widgetState.DEFAULT)
      }}
    >
      <Image
        maxW={['16', '44']}
        maxH={['16', '28']}
        alt={t('bot_icon')}
        src={`${assetsBaseUrl}/assets/widget/${customerNameFromUrl || customer}/${
          isMobile ? 'mobile' : isActiveSession ? 'active' : i18next.language
        }-${state}.png`}
      ></Image>
    </Button>
  )
}
