import { Image } from '@chakra-ui/react'
import { customerNameFromUrl } from '../../api/direct'
import { useConfig } from '../../context/config'
import { Customer } from '../../interfaces/customer.interface'
import { useDynamicTheme } from '../styles/theme'

export const Logo = () => {
  const {
    apiConfig: { customer },
  } = useConfig()
  const customerName: Customer = customerNameFromUrl || customer
  const theme = useDynamicTheme(customerName)
  return (
    <Image
      data-testid='img_customer_logo'
      h={10}
      alt={'logo'}
      {...theme.logo.widget}
    />
  )
}
