import { Customer } from '../../../interfaces/customer.interface'

export const themes = (retailer: Customer) => {
  switch (retailer) {
    case 'totalwireless':
      return {
        colors: {
          primary: '#E6FCF4',
          secondary: '#EE0000',
          header: '#E6FCF4',
          headerIcon: 'black',
          text: '#1A1C35',
          footerIcon: '#1A1C35',
          customerText: 'white',
          secondaryBtnText: 'black',
          link: '#007BFF',
          linkHover: '#0056b3',
          custLink: '#62aeff',
          custHover: '#5da5f2',
          primaryBtnHover: '#EE0000',
          secondaryBtnHover: '#00000012',
          underLine: '#EE0000',
          botIcon: '#212644',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '30px',
        },
        sizes: {
          buttonHeight: '32px',
          buttonWidth: '158px',
          dynamicInputWidth: 'calc(100% - 90px)',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: 'none',
          secondaryBorder: '1px solid #1A1C35',
          secondaryBorderHovered: '1px solid #1A1C35',
        },
      }
    case 'wfm':
      return {
        colors: {
          primary: '#1A75CF',
          secondary: '#397BBD',
          header: 'transparent',
          headerIcon: 'black',
          text: 'black',
          border: '#1A75CF',
          footerIcon: '#1A75CF',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          primaryBtnHover: 'white',
          primaryBtnHoverText: '#003896',
          secondaryBtnHover: 'white',
          secondaryBtnText: '#003896',
          primaryBtnText: 'white',
          secondaryBtnHoverText: '#1A75CF',
          underLine: '#F4B512',
          botIcon: '#000',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '4px',
        },
        sizes: {
          buttonHeight: '32px',
          buttonWidth: '158px',
          dynamicInputWidth: '100%',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: '2px solid #1A75CF',
          secondaryBorder: '1px solid #1A75CF',
          secondaryBorderHovered: '2px solid #1A75CF',
          headerBorderTop: '1px solid #F4B512',
          headerBorderBottom: '2px solid #F4B512',
        },
      }
    case 'gosmart':
      return {
        colors: {
          primary: '#414143',
          secondary: '#048FB6',
          header: '#414143',
          headerIcon: 'white',
          text: 'black',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          primaryBtnHover: '#286090',
          primaryBtnHoverText: 'white',
          secondaryBtnHover: 'white',
          secondaryBtnText: 'black',
          primaryBtnText: 'white',
          secondaryBtnHoverText: 'black',
          underLine: '#BDD531',
          botIcon: '#000',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '30px',
        },
        sizes: {
          buttonHeight: '36px',
          buttonWidth: '158px',
          dynamicInputWidth: '100%',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: 'none',
          secondaryBorder: ' 1px solid #231F20',
          secondaryBorderHovered: '1px solid #BDD531',
        },
      }
    case 'net10wireless':
      return {
        colors: {
          primary: '#53ABE9',
          secondary: '#00AEEF',
          header: 'white',
          headerIcon: 'black',
          text: 'black',
          footerIcon: 'black',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          primaryBtnHover: 'white',
          primaryBtnHoverText: 'black',
          secondaryBtnHover: '#F3FCFF',
          secondaryBtnText: 'black',
          primaryBtnText: 'white',
          secondaryBtnHoverText: 'black',
          underLine: '#00AEEF',
          botIcon: '#000',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '30px',
        },
        sizes: {
          buttonHeight: '36px',
          buttonWidth: '158px',
          dynamicInputWidth: '100%',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: '1px solid #000000',
          secondaryBorder: '1px solid #231F20',
          secondaryBorderHovered: '1px solid #231F20',
          headerBorderTop: '1px solid #707070',
          headerBorderBottom: '1px solid #707070',
        },
      }
    case 'pageplus':
      return {
        colors: {
          primary: '#414143',
          secondary: '#EE3A43',
          header: '#414143',
          headerIcon: 'white',
          text: 'black',
          footerIcon: 'black',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          primaryBtnHover: '#EB1721',
          primaryBtnHoverText: 'white',
          secondaryBtnHover: 'white',
          secondaryBtnText: 'black',
          primaryBtnText: 'white',
          secondaryBtnHoverText: 'black',
          underLine: '#EB1721',
          botIcon: '#414143',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '6px',
        },
        sizes: {
          buttonHeight: '32px',
          buttonWidth: '158px',
          dynamicInputWidth: '100%',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: 'none',
          secondaryBorder: '1px solid #414143',
          secondaryBorderHovered: '1px solid #EB1721',
        },
      }
    case 'straighttalk':
      return {
        colors: {
          primary: '#BEE81E',
          secondary: 'black',
          primaryGray: '#6C6C6C',
          header: '#BEE81E',
          headerIcon: 'black',
          text: 'black',
          footerIcon: '#BEE81E',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          custLink: '#62aeff',
          custHover: '#5da5f2',
          primaryBtnHover: '#6C6C6C',
          secondaryBtnHover: '#BEE81E',
          secondaryBtnText: 'black',
          underLine: '#BEE81E',
          botIcon: '#000',
          footer: 'black',
        },
        radii: {
          radius: '15px',
        },
        sizes: {
          buttonHeight: '32px',
          buttonWidth: '158px',
          dynamicInputWidth: 'calc(100% - 90px)',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: 'none',
          secondaryBorder: '1px solid black',
          secondaryBorderHovered: 'none',
        },
      }
    case 'tracfone':
      return {
        colors: {
          primary: '#273691',
          secondary: '#012979',
          header: '#E3E9EF',
          headerIcon: 'white',
          text: 'black',
          footerIcon: 'black',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          primaryBtnHover: '#273691',
          primaryBtnHoverText: 'white',
          secondaryBtnHover: '#E3E9EF',
          secondaryBtnText: 'black',
          primaryBtnText: 'white',
          secondaryBtnHoverText: 'black',
          underLine: '#012979',
          botIcon: '#000',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '6px',
        },
        sizes: {
          buttonHeight: '38px',
          buttonWidth: '158px',
          dynamicInputWidth: '100%',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: 'none',
          secondaryBorder: '1px solid black',
          secondaryBorderHovered: '1px solid black',
          headerBorderTop: '1px solid #ccc;',
          headerBorderBottom: '1px solid #ccc',
        },
      }
    case 'safelink':
      return {
        colors: {
          primary: '#FDBB30',
          secondary: '#FFBA24',
          header: 'white',
          headerIcon: 'black',
          text: 'black',
          border: 'black',
          footerIcon: 'black',
          customerText: 'black',
          link: '#007BFF',
          linkHover: '#0056b3',
          primaryBtnHover: '#FFCA57',
          primaryBtnHoverText: 'black',
          secondaryBtnHover: '#white',
          secondaryBtnText: 'black',
          primaryBtnText: 'black',
          secondaryBtnHoverText: 'black',
          underLine: '#FFBA24',
          botIcon: '#414143',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '5px',
        },
        sizes: {
          buttonHeight: '36px',
          buttonWidth: '158px',
          dynamicInputWidth: '100%',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: 'none',
          secondaryBorder: '1px solid black',
          secondaryBorderHovered: '1px solid #FFBA24',
          headerBorderTop: '1px solid #707070',
          headerBorderBottom: '1px solid #707070',
        },
      }
    case 'simplemobile':
      return {
        colors: {
          primary: '#95d500',
          secondary: '#231F20',
          header: '#95d500',
          headerIcon: '#231F20',
          text: '#231F20',
          footerIcon: '#231F20',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          primaryBtnHover: 'white',
          primaryBtnHoverText: '#231F20',
          secondaryBtnHover: 'white',
          secondaryBtnText: '#231F20',
          primaryBtnText: 'white',
          secondaryBtnHoverText: '#231F20',
          underLine: '#95d500',
          botIcon: '#000',
          footer: '#F5F5F5',
        },
        radii: {
          radius: '0',
        },
        sizes: {
          buttonHeight: '36px',
          buttonWidth: '158px',
          dynamicInputWidth: '100%',
        },
        borders: {
          primaryBorder: 'none',
          primaryBorderHovered: '1px solid #93D500',
          secondaryBorder: '1px solid #231F20',
          secondaryBorderHovered: '1px solid #93D500',
        },
      }
    case 'whitelabel':
      return {
        colors: {
          primary: '#626469',
          secondary: '#626469',
          header: '#626469',
          headerIcon: 'white',
          text: 'black',
          border: 'black',
          footerIcon: 'black',
          restart: 'white',
          customerText: 'white',
          link: '#007BFF',
          linkHover: '#0056b3',
          custLink: '#007BFF',
          custHover: '#0056b3',
        },
      }
    default:
      return {
        colors: {
          primary: '#1A1C35',
          secondary: '#1A1C35',
          header: 'black',
          headerIcon: 'white',
          text: 'black',
          border: 'black',
          footerIcon: 'black',
          restart: 'black',
          customerText: 'black',
          link: '#007BFF',
          linkHover: '#0056b3',
          custLink: '#007BFF',
          custHover: '#0056b3',
        },
      }
  }
}
