import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    boxSizing: 'border-box',
  },
  variants: {
    none: {
      bgColor: 'transparent',
      minWidth: 0,
      w: 'auto',
      fontWeight: 'normal',
    },
    popover: {
      bgColor: 'transparent',
      minWidth: 0,
      w: 'fit-content',
      fontWeight: 'normal',
      fontSize: 13,
      borderRadius: 0,
      _hover: {
        textDecoration: 'underline',
        textDecorationThickness: 2,
      },
    },

    popover_button: {
      color: 'widget.text',
      w: '100%',
      h: 10,
      fontSize: 14,
      justifyContent: 'center',
      _hover: {
        textDecoration: 'underline',
        textDecorationColor: 'widget.underLine',
        textDecorationThickness: 2,
      },
    },
    widget_icon: {
      bg: 'white',
      position: 'absolute',
      bottom: 5,
      right: 2,
      zIndex: 10,
      cursor: 'pointer',
      w: 16,
      h: 16,
      p: 0,
      borderRadius: 'full',
      border: '1px solid',
      borderColor: 'widget.primary',
      _hover: {
        bg: 'widget.primary',
        '> svg': {
          color: 'white',
        },
      },
    },
    option: {
      color: 'widget.secondaryBtnText',
      bg: 'white',
      border: 'widget.secondaryBorder',
      fontSize: 12,
      fontWeight: 400,
      minW: 'widget.buttonWidth',
      h: 'widget.buttonHeight',
      borderRadius: 'widget.radius',
      _hover: {
        bg: 'widget.secondaryBtnHover',
        border: 'widget.secondaryBorderHovered',
        color: 'widget.secondaryBtnHoverText',
      },
    },
    customer_option: {
      color: 'widget.customerText',
      bg: 'widget.secondary',
      fontSize: 12,
      fontWeight: 700,
      minW: 'widget.buttonWidth',
      h: 'widget.buttonHeight',
      borderRadius: 'widget.radius',
      border: 'widget.primaryBorder',
      _hover: {
        border: 'widget.primaryBorderHovered',
        color: 'widget.primaryBtnHoverText',
        bg: 'widget.primaryBtnHover',
      },
    },
    expand_collapse: {
      position: 'absolute',
      right: '8',
      zIndex: 9,
    },
  },
}
