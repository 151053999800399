import { extendTheme } from '@chakra-ui/react'
import { Config } from '../../api/dataService'
import { useConfig } from '../../context/config'
import { Customer } from '../../interfaces/customer.interface'
import { Badge } from './components/badge'
import { Button } from './components/button'
import { Container } from './components/container'
import { Drawer } from './components/drawer'
import { Popover } from './components/popover'
import { Text } from './components/text'
import { themes as allThemes } from './themes/index'

export const useDynamicTheme = (retailer: Customer) => {
  const config: Config = useConfig()
  const {
    theme: { colors, sizes, logo },
    apiConfig: { baseURL },
  } = config
  const assetsBaseUrl = baseURL
  const themes = allThemes(retailer)
  return extendTheme({
    logo: {
      widget: {
        src: `${assetsBaseUrl}/assets/logos/${retailer}.png`,
        ...logo,
      },
    },
    colors: {
      widget: {
        ...themes.colors,
        gray: {
          100: '#F2F2F2',
          200: '#E2E2E2',
          300: '#C2C2C2',
          400: '#F5F5F5',
          500: '#747676',
          700: '#474a4d',
          900: '#333333',
        },
        red: {
          100: '#EE0000',
        },

        ...colors,
      },
    },
    sizes: {
      widget: {
        heightWidget: '80%',
        heightWidgetMobile: '550px',
        widthWidget: '360px',
        ...themes.sizes,
        ...sizes,
      },
    },
    fontSizes: {
      widget: {
        sm: '14px',
        md: '16px',
        lg: '20px',
      },
    },
    radii: {
      widget: {
        ...themes.radii,
      },
    },
    borders: {
      widget: {
        ...themes.borders,
      },
    },
    components: {
      Button,
      Container,
      Text,
      Drawer,
      Badge,
      Popover,
    },
  })
}
