import {
  CreateEngagementRes,
  GetEngagementRes,
  GetMessagesRes,
  SendMessageRes,
} from '../interfaces/agent.interface'
import { Customer } from '../interfaces/customer.interface'
import { get, post } from '../utils/api'
import { getLSItem, setLSItem } from '../utils/localStorage'
import { AuthData, BotRequestData, BotResponse } from './dataService'

const queryParams = new URLSearchParams(window.location.search)
export const customerNameFromUrl: Customer = queryParams.get('customer') as Customer
const currentLangForAgent = document.documentElement.lang.includes('es') ? 'Spa' : 'Eng'
let isRequestInProgress = false

type APIError = {
  status: number
}

const createUrl = ({ user, botId }: { user: string; botId: string }) =>
  `api/v1/bots/${botId}/converse/${user}/secured`

const fetchAuthToken = async (authHost: string): Promise<string | null> => {
  try {
    const authData = await post<AuthData>(`/auth`, {}, {}, authHost)
    const authToken = authData.jwt
    const expTime = authData.exp / 60 / 60 / 1000 // convert milliseconds to hours

    setLSItem('auth_token', authToken, expTime)

    return authToken
  } catch (error) {
    console.error('Error fetching auth token:', error)
    return null
  }
}

const sendMessageAPI = async (
  requestData: BotRequestData,
  authToken: string,
  botId: string,
  botURL: string
) => {
  const user = getLSItem('user_id') || ''
  const url = createUrl({ user, botId })
  const data = await post<{ responses: BotResponse[] }>(
    url,
    requestData,
    {
      Authorization: `Bearer ${authToken}`,
    },
    botURL
  )
  return data.responses
}

export const sendMessageRequest = async (
  requestData: BotRequestData,
  botId: string,
  botURL: string,
  authHost: string
): Promise<BotResponse[] | undefined> => {
  if (isRequestInProgress) return

  isRequestInProgress = true

  let authToken = getLSItem('auth_token') || (await fetchAuthToken(authHost)) || ''
  try {
    return await sendMessageAPI(requestData, authToken, botId, botURL)
  } catch (error: unknown) {
    const apiError = error as APIError
    if (apiError?.status === 401) {
      authToken = (await fetchAuthToken(authHost)) || ''
      return sendMessageAPI(requestData, authToken, botId, botURL)
    }
    throw error
  } finally {
    isRequestInProgress = false
  }
}

export const getAgentMessages = async (agentUrl: string): Promise<GetMessagesRes> => {
  const engagementID: string = getLSItem('engagement_id') || ''
  return await get(`messages?engagementID=${engagementID}`, {}, agentUrl)
}

export const sendAgentMessage = async (
  messageText: string,
  agentURL: string
): Promise<SendMessageRes> => {
  const engagementID: string = getLSItem('engagement_id') || ''
  return await post(`message`, { engagementID, messageText }, {}, agentURL)
}

export const createEngagement = async (
  agentURL: string,
  customer: string
): Promise<CreateEngagementRes> => {
  const user_id: string = getLSItem('user_id') || ''

  return await post(
    `engage?client=${customer}&lang=${currentLangForAgent}&status=Sales&userId=${user_id}&bpEngagementId=${user_id}`,
    {},
    {},
    agentURL
  )
}

export const getEgagement = async (agentUrl: string): Promise<GetEngagementRes> => {
  const engagementID: string = getLSItem('engagement_id') || ''
  return await get(`engage?engagementID=${engagementID}`, {}, agentUrl)
}
