import { ChakraProvider } from '@chakra-ui/react'
import { I18nextProvider } from 'react-i18next'
import { customerNameFromUrl } from './api/direct'
import './App.css'
import { useDynamicTheme } from './components/styles/theme'
import { WidgetContainer } from './components/widget/WidgetContainer'
import { ButtonsProvider } from './context/buttons'
import { useConfig } from './context/config'
import { MessagesProvider } from './context/messages'
import { useDynamicI18n } from './i18n/i18n'
import { useAnalytics } from './utils/hooks/useAnalytics'

export const App = () => {
  const {
    apiConfig: { customer },
  } = useConfig()
  useAnalytics()
  const i18n = useDynamicI18n()
  const theme = useDynamicTheme(customerNameFromUrl || customer)
  return (
    <div
      className='App'
      id='chat_widget_app'
    >
      <I18nextProvider i18n={i18n}>
        <ChakraProvider
          theme={theme}
          cssVarsRoot={'#chat_widget_app'}
        >
          <MessagesProvider>
            <ButtonsProvider>
              <WidgetContainer />
            </ButtonsProvider>
          </MessagesProvider>
        </ChakraProvider>
      </I18nextProvider>
    </div>
  )
}
