import { useState } from 'preact/hooks'
import {
  BotMsgChoice,
  BotRequestData,
  BotResponse,
  choiceType,
  Config,
  isBasic,
  isRedirectionType,
  isSingleChoice,
  MessageTypes,
  MODE,
  nodeType,
} from '../../api/dataService'
import { sendMessageRequest } from '../../api/direct'
import { useButtonsContext } from '../../context/buttons'
import { useConfig } from '../../context/config'
import { Message, useMessagesContext } from '../../context/messages'
import { getLSItem, setLSItem } from '../../utils/localStorage'
import { ANALYTICS_EVENTS } from '../analytics.interface'
import { sendAnalyticsData } from './useAnalytics'

export const useBot = () => {
  const { addMessage } = useMessagesContext()
  const { addButtons } = useButtonsContext()
  const {
    apiConfig: { botURL, botId, authHost },
  }: Config = useConfig()
  const config: Record<string, any> = useConfig()
  const [isTextInputDisabled, setIsTextInputDisabled] = useState<boolean>(
    !!getLSItem<boolean>('is_input_disabled')
  )
  const [placeholder, setPlaceholder] = useState<string>(getLSItem('placeholder') || '')
  const [isPrimaryBtn, setIsPrimaryBtn] = useState<boolean>(!!getLSItem('buttons', 'isPrimaryCTA'))
  const [isAgentTriggered, setIsAgentTriggered] = useState(false)
  const hasQueryParams = (url: string) => {
    return new URL(url).searchParams.toString().length > 0
  }
  const queryParams = new URLSearchParams(window.location.search)

  const sendMessage = async (
    reqData: BotRequestData,
    { isMessageShown } = { isMessageShown: false }
  ) => {
    addButtons([])

    if (isMessageShown && reqData.type === MessageTypes.TEXT && reqData.text) {
      addMessage([{ text: reqData.text, type: reqData.type, from: MODE.USER }])
    }

    const res: BotResponse[] | undefined = await sendMessageRequest(
      reqData,
      queryParams.get('botId') || botId,
      botURL,
      authHost
    )

    if (!res) {
      return
    }
    const botResponse = res[res.length - 1]
    if (botResponse?.node === nodeType.CONNECT_AGENT) {
      setIsAgentTriggered(true)
    }

    setInput(res)
    const botResponses = res.filter(isBasic).map((r) => {
      const isCarouselType = r.type === choiceType.CAROUSEL
      if (isCarouselType) {
        return { items: r.items, from: MODE.BOT, type: MessageTypes.CAROUSEL }
      } else if (r.type === choiceType.IMAGE) {
        return {
          image: r.image,
          title: r.title,
          type: MessageTypes.IMAGE,
          from: MODE.BOT,
        }
      } else {
        return {
          text: r.text,
          type: MessageTypes.TEXT,
          from: MODE.BOT,
        }
      }
    })
    collectAnalytics(reqData.text, botResponses)
    addMessage([...botResponses])
    addButtons(res.filter(isSingleChoice).flatMap(({ choices }: BotMsgChoice) => choices))

    res.filter(isRedirectionType).forEach(({ url, type }) => {
      if (!url) return
      const isOffPageReference = type === choiceType.OFF_PAGE_REFERENCE
      if (isOffPageReference) {
        setTimeout(() => {
          window.open(url, '_blank')
        }, config.timeoutsAndDelays.redirectionDelayTime)
      } else {
        window.location.href = `${url}${hasQueryParams(url) ? '&' : '?'}showBot=true`
      }
    })
  }

  const collectAnalytics = (msg: string = '', botResponses: Message[]) => {
    if (msg) {
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.MESSAGE,
        message: msg,
        from: MODE.USER,
        to: MODE.BOT,
      })
    }

    botResponses.forEach((response) => {
      if (response.type === MessageTypes.TEXT)
        sendAnalyticsData({
          event_category: ANALYTICS_EVENTS.MESSAGE,
          message: response.text,
          from: MODE.BOT,
          to: MODE.USER,
        })
    })
  }

  const setInput = (res: BotResponse[]) => {
    const disableFreeText = !!res[res.length - 1]?.disableFreeText
    const placeholder = res[res.length - 1]?.placeholder || ''
    const isPrimary = !!res[res.length - 1]?.primaryCTA
    setIsTextInputDisabled(disableFreeText)
    setPlaceholder(placeholder)
    setLSItem('placeholder', placeholder)
    setIsPrimaryBtn(isPrimary)
    setLSItem('is_input_disabled', disableFreeText)
  }

  return {
    sendMessage,
    isTextInputDisabled,
    placeholder,
    isPrimaryBtn,
    isAgentTriggered,
    setIsAgentTriggered,
  }
}
