import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'

import { render } from 'preact'
import { Suspense } from 'preact/compat'
import { App } from './App'
import defaultConfig from './configs/defaultConfig.json'
import { ConfigProvider } from './context/config'
import './index.css'
import { fetchConfig } from './utils/hooks/useConfig'
const LoadingFallback = () => <div>Loading translations...</div>
declare global {
  interface Window {
    initializeWidget: () => void
  }
}

if (import.meta.env.VITE_INSTANCE_ENVIRONMENT !== 'dev') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_INSTANCE_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
let finalConfig: Record<string, any> = defaultConfig

const mergeConfigs = (
  defaultConfig: Record<string, any>,
  userConfig: Record<string, any>
): Record<string, any> => {
  return { ...defaultConfig, ...userConfig }
}

const getQueryParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}

const initializeWidget = async () => {
  const customer = getQueryParam('customer')
  const configUrl = getQueryParam('config')

  if (configUrl) {
    const fetchedConfig = await fetchConfig(configUrl)
    finalConfig = mergeConfigs(defaultConfig, fetchedConfig)
  } else if (customer) {
    const customerConfig = await fetchConfig(`/customer-configs/${customer}.json`)
    finalConfig = mergeConfigs(defaultConfig, customerConfig)
  }

  renderWidget()
}

initializeWidget()

function renderWidget() {
  const container = document.getElementById('widget-container')
  if (container) {
    render(
      <ConfigProvider config={finalConfig}>
        <Suspense fallback={<LoadingFallback />}>
          <ErrorBoundary fallback={<p>Something went wrong. Please try again later.</p>}>
            <App />
          </ErrorBoundary>
        </Suspense>
      </ConfigProvider>,
      container
    )
  }
}

if (typeof window !== 'undefined') {
  window.initializeWidget = renderWidget
}
