import {
  Button,
  Container,
  FormLabel,
  IconButton,
  InputGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import useDebounce from '../../utils/hooks/useDebounce'
import { BurgerIcon, CollapseIcon, ExpandIcon, SendIcon } from '../styles/components/icons'

type FooterProps = {
  onBack: () => void
  isTextInputDisabled: boolean
  placeholder: string
  onSend: (message: string, { isMessageShown }: { isMessageShown: boolean }) => void
  toggleExpandCollapse: () => void
}

export const Footer = ({
  onBack,
  isTextInputDisabled,
  placeholder,
  onSend,
  toggleExpandCollapse,
}: FooterProps) => {
  const { t } = useTranslation()
  const messageRef = useRef<HTMLTextAreaElement | null>(null)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isDisabledButton = isTextInputDisabled
  const isMobile = useBreakpointValue({ base: true, md: false })

  const handleInputChange = useDebounce(() => {
    const messageValue = messageRef.current?.value.trim() || ''
    setIsButtonEnabled(!!messageValue)
  }, 300)

  const handleSend = () => {
    const message = messageRef.current?.value.trim()
    if (message) {
      onSend(message, { isMessageShown: true })
      if (messageRef.current) {
        messageRef.current.value = ''
      }
      setIsButtonEnabled(false)
    }
  }

  const handleBack = () => {
    onBack()
    onClose()
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Prevent the default behavior (which would be adding a newline) and send the message
      event.preventDefault()
      handleSend()
    }
  }

  const toggleExpandCollapseBtn = () => {
    setIsExpanded((prev) => !prev)
    toggleExpandCollapse()
  }

  return (
    <InputGroup
      borderBottomRadius={'widget.sm'}
      h={14}
      gap={3}
      px={3}
      alignItems={'center'}
      bgColor={'widget.footer'}
    >
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement='auto-start'
        variant='custom'
      >
        <PopoverTrigger>
          <IconButton
            variant={'none'}
            aria-label={t('open_menu')}
            icon={<BurgerIcon />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <Button
            mb={1}
            variant={'popover_button'}
            aria-label={t('start_over')}
            onClick={handleBack}
          >
            {t('start_over')}
          </Button>
        </PopoverContent>
      </Popover>
      <Container
        variant={'input_wrapper'}
        w={['100%', 'widget.dynamicInputWidth']}
      >
        <FormLabel
          htmlFor='message'
          w={'100%'}
          h={8}
          m={0}
        >
          <Textarea
            id={'message'}
            ref={messageRef}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            border='none'
            placeholder={placeholder}
            h={'inherit'}
            minW={0}
            minH={0}
            fontSize={12}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            resize={'none'}
            isDisabled={isDisabledButton}
            css={{
              '&::-webkit-scrollbar': {
                width: 10,
                borderRadius: 4,
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: 6,
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: 6,
                backgroundColor: 'transparent',
              },
            }}
          />
        </FormLabel>
      </Container>
      {!isMobile && (
        <IconButton
          variant='expand_collapse'
          aria-label={t(isExpanded ? 'collapse_button' : 'expand_button')}
          icon={isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          onClick={toggleExpandCollapseBtn}
        ></IconButton>
      )}
      <IconButton
        variant={'none'}
        aria-label={t('send_message')}
        icon={<SendIcon />}
        onClick={handleSend}
        isDisabled={!isButtonEnabled}
        marginLeft={'auto'}
      />
    </InputGroup>
  )
}
