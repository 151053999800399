export const MessageTypes = {
  TEXT: 'text',
  CAROUSEL: 'carousel',
  IMAGE: 'image',
} as const

export const EventTypes = {
  TEXT: 'text',
  PROACTIVE_TRIGGER: 'proactive-trigger',
  SEND_CHAT_DATA: 'send-chat-data',
}

export const MODE = {
  BOT: 'bot',
  AGENT: 'agent',
  USER: 'user',
}

export const nodeType = {
  CONNECT_AGENT: '4250_im_connectingAgent',
}

export type BotResponse = {
  text: string
  placeholder: string
  disableFreeText: boolean
  primaryCTA: boolean
  node: string
} & (BotMsgChoice | CarouselItems | ImageType | BackgroundNavigation | PageOffNavigation)

export type BotMsgChoice = {
  type: typeof choiceType.SINGLE_CHOICE
  choices: Array<{
    title: string
    value: string
  }>
}

type CarouselItems = {
  type: typeof choiceType.CAROUSEL
  items: CarouselItem[]
}

type ImageType = {
  type: typeof choiceType.IMAGE
  title: string
  image: string
}

type BackgroundNavigation = {
  type: typeof choiceType.BACKGROUND_NAVIGATION
  url: string
}

type PageOffNavigation = {
  type: typeof choiceType.OFF_PAGE_REFERENCE
  url: string
}
export type CarouselItem = {
  actions: CarouselActions[]
  image: string
  markdown: boolean
  subtitle: string
  title: string
}

export type CarouselActions = {
  action: string
  title: string
  url?: string
  text?: string
  payload?: string
}

export type AuthData = {
  jwt: string
  exp: number
}

export const choiceType = {
  SINGLE_CHOICE: 'single-choice',
  BACKGROUND_NAVIGATION: 'background-navigation',
  CAROUSEL: 'carousel',
  OFF_PAGE_REFERENCE: 'off-page-reference',
  IMAGE: 'image',
} as const

export type Payload = {
  language?: 'en' | 'es'
  intent?: string
  previousBotsessionId?: string
  lpEngagementId?: string
  channel?: 'web'
}

export type ChatConfig = {
  intent?: string
}

export type BotRequestData = {
  text?: string
  payload: Payload
  type: (typeof EventTypes)[keyof typeof EventTypes]
}

export const isBasic = (a: unknown): a is BotResponse =>
  (!!(a as BotResponse).text ||
    (a as BotResponse).type == choiceType.CAROUSEL ||
    (a as BotResponse).type == choiceType.IMAGE) &&
  (a as BotResponse).node !== nodeType.CONNECT_AGENT
export const isRedirectionType = (
  a: unknown
): a is BotResponse & (BackgroundNavigation | PageOffNavigation) =>
  (a as BotResponse).type == choiceType.BACKGROUND_NAVIGATION ||
  (a as BotResponse).type == choiceType.OFF_PAGE_REFERENCE
export const isSingleChoice = (a: unknown): a is BotResponse & BotMsgChoice =>
  (a as BotResponse).type === choiceType.SINGLE_CHOICE && Array.isArray((a as BotMsgChoice).choices)

export type Config = {
  apiConfig: Record<string, string>
  theme: {
    logo: Record<string, string>
    colors: Record<string, string>
    sizes: Record<string, string>
  }
  translations: {
    en: Record<string, string>
    es: Record<string, string>
  }
  timeoutsAndDelays: {
    userIdExpirationTime: number
    intervalToGetMessages: number
    historyExpirationTime: number
    redirectionDelayTime: number
  }
}
