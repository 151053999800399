type icons = {
  [key: string]: JSX.Element
}

type customerIcons = {
  [customerName: string]: icons
}

const defaultBotIcon: JSX.Element = (
  <svg viewBox='0 0 46 32.708'>
    <g
      id='Group_88'
      data-name='Group 88'
      transform='translate(-1364 -260.69)'
    >
      <g
        id='Rectangle_55'
        data-name='Rectangle 55'
        transform='translate(1369.5 265.69)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='2'
      >
        <rect
          width='35'
          height='27.708'
          stroke='none'
        />
        <rect
          x='1'
          y='1'
          width='33'
          height='25.708'
          fill='none'
        />
      </g>
      <g
        id='Rectangle_56'
        data-name='Rectangle 56'
        transform='translate(1404 276)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='2'
      >
        <rect
          width='6'
          height='7'
          stroke='none'
        />
        <rect
          x='1'
          y='1'
          width='4'
          height='5'
          fill='none'
        />
      </g>
      <g
        id='Rectangle_57'
        data-name='Rectangle 57'
        transform='translate(1364 277)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='2'
      >
        <rect
          width='6'
          height='7'
          stroke='none'
        />
        <rect
          x='1'
          y='1'
          width='4'
          height='5'
          fill='none'
        />
      </g>
      <g
        id='Ellipse_21'
        data-name='Ellipse 21'
        transform='translate(1375 271.938)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='1.5'
      >
        <ellipse
          cx='5'
          cy='5.625'
          rx='5'
          ry='5.625'
          stroke='none'
        />
        <ellipse
          cx='5'
          cy='5.625'
          rx='4.25'
          ry='4.875'
          fill='none'
        />
      </g>
      <g
        id='Ellipse_22'
        data-name='Ellipse 22'
        transform='translate(1389 271.938)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='1.5'
      >
        <ellipse
          cx='5'
          cy='5.625'
          rx='5'
          ry='5.625'
          stroke='none'
        />
        <ellipse
          cx='5'
          cy='5.625'
          rx='4.25'
          ry='4.875'
          fill='none'
        />
      </g>
      <path
        id='Path_204'
        data-name='Path 204'
        d='M0,0H9.4'
        transform='translate(1382.299 286.058)'
        fill='none'
        stroke='currentColor'
        stroke-width='1.5'
      />
      <g
        id='Rectangle_58'
        data-name='Rectangle 58'
        transform='translate(1394 260.69)'
        stroke='currentColor'
        stroke-width='1'
      >
        <rect
          width='5'
          height='6'
          stroke='none'
        />
        <rect
          x='0.5'
          y='0.5'
          width='4'
          height='5'
          fill='none'
        />
      </g>
    </g>
  </svg>
)
const defaultBotIcon_1 = (
  <svg
    viewBox='0 0 45.141 37.963'
    fill='currentColor'
  >
    <g
      id='BOT_icon'
      data-name='BOT icon'
      transform='translate(-1219.43 -2007.134)'
    >
      <g
        id='Rectangle_51'
        data-name='Rectangle 51'
        transform='translate(1224.5 2016.097)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='3'
      >
        <rect
          width='35'
          height='29'
          rx='3'
          stroke='none'
        />
        <rect
          x='1.5'
          y='1.5'
          width='32'
          height='26'
          rx='1.5'
          fill='none'
        />
      </g>
      <path
        id='Path_156'
        data-name='Path 156'
        d='M.892.883h3.57V7.961H.892Zm0,0'
        transform='translate(1258.608 2026.175)'
        fill='none'
        stroke='currentColor'
        stroke-width='3'
      />
      <path
        id='Path_157'
        data-name='Path 157'
        d='M.895.883h3.57V7.961H.895Zm0,0'
        transform='translate(1220.035 2027.175)'
        fill='none'
        stroke='currentColor'
        stroke-width='3'
      />
      <g
        id='Ellipse_20'
        data-name='Ellipse 20'
        transform='translate(1245 2022.304)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='3'
      >
        <ellipse
          cx='4.764'
          cy='5.754'
          rx='4.764'
          ry='5.754'
          stroke='none'
        />
        <ellipse
          cx='4.764'
          cy='5.754'
          rx='3.264'
          ry='4.254'
          fill='none'
        />
      </g>
      <g
        id='Path_159'
        data-name='Path 159'
        transform='translate(1230.875 2022.304)'
        fill='#fff'
      >
        <path
          d='M 4.8955078125 9.895201683044434 C 3.023217916488647 9.895201683044434 1.499997854232788 8.012161254882812 1.499997854232788 5.697601318359375 C 1.499997854232788 3.383041381835938 3.023217916488647 1.500001311302185 4.8955078125 1.500001311302185 C 6.767797946929932 1.500001311302185 8.291017532348633 3.383041381835938 8.291017532348633 5.697601318359375 C 8.291017532348633 8.012161254882812 6.767797946929932 9.895201683044434 4.8955078125 9.895201683044434 Z'
          stroke='none'
        />
        <path
          d='M 4.8955078125 3.000000953674316 C 4.358147621154785 3.000000953674316 3.953837871551514 3.339271545410156 3.709287643432617 3.623891353607178 C 3.258527755737305 4.148511409759521 2.999997615814209 4.904351234436035 2.999997615814209 5.697601318359375 C 2.999997615814209 6.490851402282715 3.258527755737305 7.246691226959229 3.709287643432617 7.771311283111572 C 3.953837871551514 8.055931091308594 4.358147621154785 8.395200729370117 4.8955078125 8.395200729370117 C 5.432868003845215 8.395200729370117 5.837177753448486 8.055931091308594 6.081727981567383 7.771311283111572 C 6.532487869262695 7.246691226959229 6.791017532348633 6.490851402282715 6.791017532348633 5.697601318359375 C 6.791017532348633 4.904351234436035 6.532487869262695 4.148511409759521 6.081727981567383 3.623891353607178 C 5.837177753448486 3.339271545410156 5.432868003845215 3.000000953674316 4.8955078125 3.000000953674316 M 4.8955078125 9.5367431640625e-07 C 7.599217891693115 9.5367431640625e-07 9.791017532348633 2.550901412963867 9.791017532348633 5.697601318359375 C 9.791017532348633 8.844301223754883 7.599217891693115 11.39520168304443 4.8955078125 11.39520168304443 C 2.191797733306885 11.39520168304443 -1.9073486328125e-06 8.844301223754883 -1.9073486328125e-06 5.697601318359375 C -1.9073486328125e-06 2.550901412963867 2.191797733306885 9.5367431640625e-07 4.8955078125 9.5367431640625e-07 Z'
          stroke='none'
          fill='currentColor'
        />
      </g>
      <path
        id='Path_158'
        data-name='Path 158'
        d='M0,0H9.4'
        transform='translate(1237.667 2036.597)'
        fill='none'
        stroke='currentColor'
        stroke-width='3'
      />
      <g
        id='Path_164'
        data-name='Path 164'
        transform='translate(1247.569 2011.134)'
        fill='currentColor'
      >
        <path
          d='M 3.5 4.5 L 0.5 4.5 L 0.5 0.5 L 3.5 0.5 L 3.5 4.5 Z'
          stroke='none'
        />
        <path
          d='M 1 1 L 1 4 L 3 4 L 3 1 L 1 1 M 0 0 L 4 0 L 4 5 L 0 5 L 0 0 Z'
          stroke='none'
          fill='currentColor'
        />
      </g>
      <g
        id='Rectangle_53'
        data-name='Rectangle 53'
        transform='translate(1248.569 2007.134)'
        fill='currentColor'
        stroke='currentColor'
        stroke-width='1'
      >
        <rect
          width='2'
          height='5'
          stroke='none'
        />
        <rect
          x='0.5'
          y='0.5'
          width='1'
          height='4'
          fill='none'
        />
      </g>
    </g>
  </svg>
)

const defaultSendIcon: JSX.Element = (
  <path
    d='M1.923,9.37c-.51-.2-.5-.51.034-.689L21.043,2.319c.529-.176.832.12.684.638L16.273,22.043c-.15.529-.475.553-.717.07L11,13Zm4.89-.2,5.636,2.255,3.04,6.082L19.035,5.1,6.812,9.17Z'
    fill='currentColor'
  />
)

const defaultExpandIcon: JSX.Element = (
  <path
    viewBox='0 0 17 17'
    id='Expand'
    d='M0,36.225v-3.54A.75.75,0,0,1,.8,32H4.955a.377.377,0,0,1,.4.343v1.142a.377.377,0,0,1-.4.343H2.143v2.4a.377.377,0,0,1-.4.343H.4A.377.377,0,0,1,0,36.225Zm9.643-3.882v1.142a.377.377,0,0,0,.4.343h2.813v2.4a.377.377,0,0,0,.4.343H14.6a.377.377,0,0,0,.4-.343v-3.54A.75.75,0,0,0,14.2,32H10.045A.377.377,0,0,0,9.643,32.343ZM14.6,40.222H13.259a.377.377,0,0,0-.4.343v2.4H10.045a.377.377,0,0,0-.4.343v1.142a.377.377,0,0,0,.4.343H14.2A.75.75,0,0,0,15,44.1v-3.54A.377.377,0,0,0,14.6,40.222ZM5.357,44.447V43.3a.377.377,0,0,0-.4-.343H2.143v-2.4a.377.377,0,0,0-.4-.343H.4a.377.377,0,0,0-.4.343V44.1a.75.75,0,0,0,.8.685H4.955A.377.377,0,0,0,5.357,44.447Z'
    transform='translate(0 -32)'
    fill='currentColor'
    stroke='currentColor'
    stroke-width='0'
  />
)
const defaultCollapseIcon: JSX.Element = (
  <path
    viewBox='0 0 24 24'
    id='expanded'
    d='M20.681,39.668H14.8a1.142,1.142,0,0,1-1.138-1.15V32.575A.574.574,0,0,1,14.23,32h1.9a.574.574,0,0,1,.569.575V36.6h3.984a.574.574,0,0,1,.569.575v1.917A.574.574,0,0,1,20.681,39.668ZM7.589,38.518V32.575A.574.574,0,0,0,7.02,32h-1.9a.574.574,0,0,0-.569.575V36.6H.569A.574.574,0,0,0,0,37.176v1.917a.574.574,0,0,0,.569.575H6.451A1.142,1.142,0,0,0,7.589,38.518Zm0,14.378V46.953A1.142,1.142,0,0,0,6.451,45.8H.569A.574.574,0,0,0,0,46.378V48.3a.574.574,0,0,0,.569.575H4.554V52.9a.574.574,0,0,0,.569.575h1.9A.574.574,0,0,0,7.589,52.9Zm9.107,0V48.87h3.984a.574.574,0,0,0,.569-.575V46.378a.574.574,0,0,0-.569-.575H14.8a1.142,1.142,0,0,0-1.138,1.15V52.9a.574.574,0,0,0,.569.575h1.9A.574.574,0,0,0,16.7,52.9Z'
    transform='translate(0 -32)'
    fill='currentColor'
    stroke='currentColor'
    stroke-width='0'
  />
)

export const customerIcons: customerIcons = {
  straighttalk: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg viewBox='0 0 50 46.01'>
        <g
          id='surface1'
          transform='translate(0 0.005)'
        >
          <path
            id='Path_87'
            data-name='Path 87'
            d='M8.93,12.383H41.07a5.335,5.335,0,0,1,5.359,5.309v23A5.332,5.332,0,0,1,41.07,46H8.93A5.332,5.332,0,0,1,3.57,40.691v-23A5.335,5.335,0,0,1,8.93,12.383Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_88'
            data-name='Path 88'
            d='M5.358,1.326H37.5a4,4,0,0,1,4.019,3.98v23a4,4,0,0,1-4.019,3.98H5.358a4,4,0,0,1-4.02-3.98v-23A4,4,0,0,1,5.358,1.326Zm0,0'
            transform='translate(3.571 12.385)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
          <path
            id='Path_89'
            data-name='Path 89'
            d='M44.645,23H50v8.848H44.645Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_90'
            data-name='Path 90'
            d='M.892.883h3.57V7.961H.892Zm0,0'
            transform='translate(44.643 23)'
            fill='none'
            stroke='#010101'
            stroke-width='1.777'
          />
          <path
            id='Path_91'
            data-name='Path 91'
            d='M0,23H5.355v8.848H0Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_92'
            data-name='Path 92'
            d='M.895.883h3.57V7.961H.895Zm0,0'
            transform='translate(0 23)'
            fill='none'
            stroke='#010101'
            stroke-width='1.777'
          />
          <path
            id='Path_93'
            data-name='Path 93'
            d='M27.23,6.191C27.23,2.773,26.234,0,25,0s-2.23,2.773-2.23,6.191,1,6.191,2.23,6.191S27.23,9.613,27.23,6.191Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_94'
            data-name='Path 94'
            d='M3.127,6.191c0-2.687-.4-4.863-.895-4.863S1.338,3.5,1.338,6.191s.4,4.867.895,4.867S3.127,8.879,3.127,6.191Zm0,0'
            transform='translate(22.768)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
          <path
            id='Path_95'
            data-name='Path 95'
            d='M37.5,27.422A4.465,4.465,0,1,1,33.035,23,4.445,4.445,0,0,1,37.5,27.422Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_96'
            data-name='Path 96'
            d='M7.589,4.422A3.125,3.125,0,1,1,4.464,1.328,3.111,3.111,0,0,1,7.589,4.422Zm0,0'
            transform='translate(28.571 23)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
          <path
            id='Path_97'
            data-name='Path 97'
            d='M0,0H11.011'
            transform='translate(19.493 36.269)'
            fill='none'
            stroke='#010101'
            stroke-width='2.679'
          />
          <path
            id='Path_98'
            data-name='Path 98'
            d='M23.215,27.422A4.465,4.465,0,1,1,18.75,23,4.445,4.445,0,0,1,23.215,27.422Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_99'
            data-name='Path 99'
            d='M7.589,4.422A3.125,3.125,0,1,1,4.464,1.328,3.111,3.111,0,0,1,7.589,4.422Zm0,0'
            transform='translate(14.286 23)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },
  totalwireless: {
    sendIcon: (
      <svg viewBox='0 0 18 18'>
        <path
          d='M17.6891 8.73969L0.81398 0.302279C0.616898 0.203364 0.378954 0.230024 0.207036 0.369551C0.0356171 0.509327 -0.0378839 0.736308 0.0189236 0.950333L2.23018 9.24273L0.0189236 17.5354C-0.0378839 17.7494 0.0356171 17.9764 0.207036 18.1162C0.309439 18.1996 0.435512 18.2427 0.562332 18.2427C0.648042 18.2427 0.73425 18.2233 0.81398 18.1834L17.6891 9.74603C17.8797 9.6506 18 9.45601 18 9.24273C18 9.02946 17.8797 8.83487 17.6891 8.73969ZM1.42765 16.6187L3.24474 9.80533H10.1249C10.4356 9.80533 10.6875 9.55343 10.6875 9.24273C10.6875 8.93204 10.4356 8.68014 10.1249 8.68014H3.24474L1.42765 1.86698L16.1799 9.24298L1.42765 16.6187Z'
          fill='currentColor'
        />
      </svg>
    ),
    botIcon: (
      <svg viewBox='0 0 40 38'>
        <path
          d='M35.6912 14.6249H35.3436V11.9742C35.3436 9.4038 33.2525 7.31217 30.6816 7.31217H29.2497V2.43757H30.4685C31.1422 2.43757 31.6873 1.8919 31.6873 1.21878C31.6873 0.545668 31.1422 0 30.4685 0H25.5934C24.9197 0 24.3746 0.545668 24.3746 1.21878C24.3746 1.8919 24.9197 2.43757 25.5934 2.43757H26.8122V7.3127H8.31836C5.74745 7.3127 3.65635 9.40434 3.65635 11.9747V14.6254H3.30882C1.48439 14.6254 0 16.1098 0 17.9342V23.4766C0 25.301 1.48439 26.7854 3.30882 26.7854H3.65635V29.4634C3.65635 32.0338 5.74745 34.1254 8.31836 34.1254H14.6254V37.7818C14.6254 38.216 14.8562 38.6177 15.2327 38.8361C15.4217 38.9454 15.6327 39.0005 15.8442 39.0005C16.0525 39.0005 16.2619 38.947 16.4488 38.8399L24.6991 34.1254H30.6822C33.2531 34.1254 35.3442 32.0338 35.3442 29.4634V26.7854H35.6917C37.5161 26.7854 39.0005 25.301 39.0005 23.4766V17.9342C39.0005 16.1098 37.5161 14.6254 35.6917 14.6254L35.6912 14.6249ZM3.30882 24.3478C2.82794 24.3478 2.43757 23.9569 2.43757 23.4766V17.9342C2.43757 17.4539 2.82794 17.063 3.30882 17.063H3.65635V24.3484H3.30882V24.3478ZM32.9061 29.4634C32.9061 30.6897 31.9085 31.6878 30.6816 31.6878H24.3746C24.1625 31.6878 23.9542 31.743 23.77 31.8485L17.0619 35.6815V32.9066C17.0619 32.2335 16.5168 31.6878 15.8431 31.6878H8.31729C7.08994 31.6878 6.09285 30.6897 6.09285 29.4634V11.9747C6.09285 10.7484 7.09047 9.75027 8.31729 9.75027H30.6811C31.9085 9.75027 32.9055 10.7484 32.9055 11.9747L32.9061 29.4634ZM36.5624 23.476C36.5624 23.9564 36.1721 24.3473 35.6912 24.3473H35.3436V17.0619H35.6912C36.1721 17.0619 36.5624 17.4528 36.5624 17.9331V23.476Z'
          fill='currentColor'
        />
        <path
          d='M18.2815 17.0626C18.2815 14.3745 16.0951 12.1875 13.4064 12.1875C10.7177 12.1875 8.53125 14.3745 8.53125 17.0626C8.53125 19.7508 10.7177 21.9378 13.4064 21.9378C16.0951 21.9378 18.2815 19.7508 18.2815 17.0626ZM10.9688 17.0626C10.9688 15.7185 12.0628 14.6251 13.4064 14.6251C14.7499 14.6251 15.844 15.7185 15.844 17.0626C15.844 18.4067 14.7499 19.5002 13.4064 19.5002C12.0628 19.5002 10.9688 18.4067 10.9688 17.0626Z'
          fill='currentColor'
        />
        <path
          d='M25.5939 12.1873C22.9052 12.1873 20.7188 14.3743 20.7188 17.0624C20.7188 19.7506 22.9052 21.9376 25.5939 21.9376C28.2826 21.9376 30.469 19.7506 30.469 17.0624C30.469 14.3743 28.2826 12.1873 25.5939 12.1873ZM25.5939 19.5C24.2504 19.5 23.1563 18.4065 23.1563 17.0624C23.1563 15.7183 24.2504 14.6249 25.5939 14.6249C26.9375 14.6249 28.0315 15.7183 28.0315 17.0624C28.0315 18.4065 26.9375 19.5 25.5939 19.5Z'
          fill='currentColor'
        />
        <path
          d='M25.5939 24.3751C24.9203 24.3751 24.3751 24.9208 24.3751 25.5939C24.3751 26.267 23.8289 26.8127 23.1563 26.8127H15.8436C15.1711 26.8127 14.6249 26.266 14.6249 25.5939C14.6249 24.9219 14.0797 24.3751 13.4061 24.3751C12.7324 24.3751 12.1873 24.9208 12.1873 25.5939C12.1873 27.6101 13.8275 29.2503 15.8436 29.2503H23.1563C25.1725 29.2503 26.8127 27.6101 26.8127 25.5939C26.8127 24.9208 26.2676 24.3751 25.5939 24.3751Z'
          fill='currentColor'
        />
      </svg>
    ),
    expandIcon: (
      <svg viewBox='0 0 80 86'>
        <path
          fill='currentColor'
          fontSize='22px'
          strokeWidth='0'
          d='M39,14.4c0,1.2-1,2.3-2.3,2.3h-18.7c-2.1,0-3.9,1.7-3.9,3.9v18.7c0,1.2-1,2.3-2.3,2.3s-2.3-1-2.3-2.3v-18.7c0-4.6,3.8-8.4,8.4-8.4h18.7c1.2,0,2.3,1,2.3,2.3ZM73.5,12.2h-18.7c-1.2,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3h18.7c2.1,0,3.9,1.7,3.9,3.9v18.7c0,1.2,1,2.3,2.3,2.3s2.3-1,2.3-2.3v-18.7c0-4.6-3.8-8.4-8.4-8.4ZM79.6,55.1c-1.2,0-2.3,1-2.3,2.3v18.7c0,2.1-1.7,3.9-3.9,3.9h-18.7c-1.2,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3h18.7c4.6,0,8.4-3.8,8.4-8.4v-18.7c0-1.2-1-2.3-2.3-2.3ZM36.7,79.9h-18.7c-2.1,0-3.9-1.7-3.9-3.9v-18.7c0-1.2-1-2.3-2.3-2.3s-2.3,1-2.3,2.3v18.7c0,4.6,3.8,8.4,8.4,8.4h18.7c1.2,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3Z'
        />
      </svg>
    ),
    collapseIcon: (
      <svg viewBox='0 0 250 290'>
        <path
          fill='currentColor'
          fontSize='22px'
          strokeWidth='0'
          d='M119.75,34.69c4.51,0,8.17,3.66,8.17,8.17v67.75c0,16.72-13.6,30.32-30.32,30.32H29.86c-4.51,0-8.17-3.66-8.17-8.17s3.66-8.17,8.17-8.17h67.74c7.71,0,13.98-6.27,13.98-13.98V42.86c0-4.51,3.66-8.17,8.17-8.17Z'
        />
        <path
          fill='currentColor'
          fontSize='22px'
          strokeWidth='0'
          d='M185.13,34.69c4.51,0,8.17,3.66,8.17,8.17v67.75c0,7.71,6.27,13.98,13.98,13.98h67.74c4.51,0,8.17,3.66,8.17,8.17s-3.66,8.17-8.17,8.17h-67.74c-16.72,0-30.32-13.6-30.32-30.32V42.86c0-4.51,3.66-8.17,8.17-8.17Z'
        />
        <path
          fill='currentColor'
          fontSize='22px'
          strokeWidth='0'
          d='M207.28,189.96h67.74c4.51,0,8.17,3.66,8.17,8.17s-3.66,8.17-8.17,8.17h-67.74c-7.71,0-13.98,6.27-13.98,13.98v67.74c0,4.51-3.66,8.17-8.17,8.17s-8.17-3.66-8.17-8.17v-67.74c0-16.72,13.6-30.32,30.32-30.32Z'
        />
        <path
          fill='currentColor'
          fontSize='22px'
          strokeWidth='0'
          d='M29.86,189.96h67.74c16.72,0,30.32,13.6,30.32,30.32v67.74c0,4.51-3.66,8.17-8.17,8.17s-8.17-3.66-8.17-8.17v-67.74c0-7.71-6.27-13.98-13.98-13.98H29.86c-4.51,0-8.17-3.66-8.17-8.17s3.66-8.17,8.17-8.17Z'
        />
      </svg>
    ),
  },

  wfm: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon_1,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },
  tracfone: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon_1,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },
  simplemobile: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },
  gosmart: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },
  pageplus: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon_1,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },
  safelink: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon_1,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },

  net10wireless: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg viewBox='0 0 42 34.708'>
        <g
          id='BOT_icon_'
          data-name='BOT icon '
          transform='translate(-1366 -258.69)'
        >
          <g
            id='Rectangle_55'
            data-name='Rectangle 55'
            transform='translate(1369.5 265.69)'
            fill='#fff'
            stroke='currentColor'
            stroke-width='2'
          >
            <rect
              width='35'
              height='27.708'
              stroke='none'
            />
            <rect
              x='1'
              y='1'
              width='33'
              height='25.708'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_56'
            data-name='Rectangle 56'
            transform='translate(1403 276)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <rect
              width='5'
              height='6.19'
              stroke='none'
            />
            <rect
              x='0.75'
              y='0.75'
              width='3.5'
              height='4.69'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_57'
            data-name='Rectangle 57'
            transform='translate(1366 276)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <rect
              width='5'
              height='6.188'
              stroke='none'
            />
            <rect
              x='0.75'
              y='0.75'
              width='3.5'
              height='4.688'
              fill='none'
            />
          </g>
          <g
            id='Ellipse_21'
            data-name='Ellipse 21'
            transform='translate(1377 272.938)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <circle
              cx='4.5'
              cy='4.5'
              r='4.5'
              stroke='none'
            />
            <circle
              cx='4.5'
              cy='4.5'
              r='3.75'
              fill='none'
            />
          </g>
          <g
            id='Ellipse_22'
            data-name='Ellipse 22'
            transform='translate(1389 272.938)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <circle
              cx='4.5'
              cy='4.5'
              r='4.5'
              stroke='none'
            />
            <circle
              cx='4.5'
              cy='4.5'
              r='3.75'
              fill='none'
            />
          </g>
          <path
            id='Path_204'
            data-name='Path 204'
            d='M0,0H9.4'
            transform='translate(1382.299 286.058)'
            fill='none'
            stroke='#000'
            stroke-width='1.5'
          />
          <g
            id='Rectangle_58'
            data-name='Rectangle 58'
            transform='translate(1399 258.69)'
            stroke='#000'
            stroke-width='1'
          >
            <rect
              width='2'
              height='8'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='1'
              height='7'
              fill='none'
            />
          </g>
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
  },
}
